const initUpdateNavbarOnScroll = () => {
  const navbar = document.querySelector('.navbar-northern');
  const blackLogo = document.getElementById('black-logo');
  const whiteLogo = document.getElementById('white-logo');

  if (navbar) {
    window.addEventListener('scroll', () => {
      if (window.scrollY >= 100) {
        navbar.classList.add('navbar-northern-white');
        blackLogo.classList.remove("d-none");
        whiteLogo.classList.add("d-none")
      } else {
        navbar.classList.remove('navbar-northern-white');
        blackLogo.classList.add("d-none");
        whiteLogo.classList.remove("d-none")
      }
    });
  }
}

export { initUpdateNavbarOnScroll };
