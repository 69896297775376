const customerTabs = () => {
	const decodeBtn = document.getElementById('decode-btn');
	const roteirofyBtn = document.getElementById('roteirofy-btn');
	const saudabeBtn = document.getElementById('saudabe-btn');

	const decodeTest = document.getElementById('decode-testimonial');
	const roteirofyTest = document.getElementById('roteirofy-testimonial');
	const saudabeTest = document.getElementById('saudabe-testimonial');

	if (decodeBtn && roteirofyBtn && saudabeBtn) {
		decodeBtn.addEventListener("click", (event) => {
			event.currentTarget.classList.add("active");
			roteirofyBtn.classList.remove("active");
			saudabeBtn.classList.remove("active");

			decodeTest.classList.remove("d-none");
			roteirofyTest.classList.add("d-none");
			saudabeTest.classList.add("d-none");
		});

		roteirofyBtn.addEventListener("click", (event) => {
			event.currentTarget.classList.add("active");
			decodeBtn.classList.remove("active");
			saudabeBtn.classList.remove("active");

			roteirofyTest.classList.remove("d-none");
			decodeTest.classList.add("d-none");
			saudabeTest.classList.add("d-none");
		});

		saudabeBtn.addEventListener("click", (event) => {
			event.currentTarget.classList.add("active");
			decodeBtn.classList.remove("active");
			roteirofyBtn.classList.remove("active");

			saudabeTest.classList.remove("d-none");
			decodeTest.classList.add("d-none");
			roteirofyTest.classList.add("d-none");
		});


	}

}
  
export { customerTabs };
  